import React, { useState } from 'react';
import { ChevronDown } from 'react-feather';
import Spinner from 'seedly-component-library/spinner-new';
import { useIsFetchingGroups } from 'entity/group/groupHooks';
import JoinGroupListItem from '../JoinGroupListItem';

const GROUP_PER_PAGE = 10;

const JoinGroupContent = ({
  groups,
  selectedGroupIds,
  handleSelectedGroupIds,
}) => {
  const { topThreeGroups, restOfGroups } = groups;
  const isFetching = useIsFetchingGroups();

  const [showMoreGroups, setShowMoreGroups] = useState(false);

  const groupListPage = restOfGroups.slice(
    0,
    showMoreGroups ? restOfGroups.length : GROUP_PER_PAGE,
  );

  if (isFetching) {
    return (
      <div className="flex justify-center w-full gap-4 m-4">
        <Spinner />
      </div>
    );
  }

  return (
    <div className="flex flex-col w-full gap-4">
      <p>
        Join 3 or more community groups to fill your homepage with content
        you’re interested in!
      </p>

      <p className="text-sm font-bold text-start">Recommended for you:</p>
      {topThreeGroups.map(group => {
        const isSelected = selectedGroupIds.includes(group.id);

        return (
          <JoinGroupListItem
            key={group.id}
            name={group.name}
            discussionsCount={group.discussionsCount}
            iconImage={group.profileImage}
            isSelected={isSelected}
            onJoinClick={() => {
              if (isSelected) {
                handleSelectedGroupIds(
                  selectedGroupIds.filter(id => group.id !== id),
                );
              } else {
                handleSelectedGroupIds([...selectedGroupIds, group.id]);
              }
            }}
          />
        );
      })}
      <p className="text-sm font-bold text-start">
        Browse more community groups:
      </p>
      {groupListPage.map(group => {
        const isSelected = selectedGroupIds.includes(group.id);

        return (
          <JoinGroupListItem
            key={group.id}
            name={group.name}
            discussionsCount={group.discussionsCount}
            iconImage={group.profileImage}
            isSelected={isSelected}
            onJoinClick={() => {
              if (isSelected) {
                handleSelectedGroupIds(
                  selectedGroupIds.filter(id => group.id !== id),
                );
              } else {
                handleSelectedGroupIds([...selectedGroupIds, group.id]);
              }
            }}
          />
        );
      })}
      {!showMoreGroups && (
        <span
          className="text-blue-500 inline-flex justify-center items-center w-full cursor-pointer"
          onClick={() => {
            setShowMoreGroups(true);
          }}
        >
          Discover more groups <ChevronDown size={16} className="ml-1" />
        </span>
      )}
    </div>
  );
};

export default JoinGroupContent;
