import React, { useState, useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'reducer';
import Dialog from 'seedly-component-library/dialog';
import mixpanel, { trackedEvents } from 'utils/mixpanel';
import { useSelectGroupList } from 'entity/group/groupHooks';
import { closeJoinGroupModal } from 'dispatcher/modalDispatcher';
import { filterGroups } from 'entity/group/groupUtils';
import JoinGroupContent from '../JoinGroupContent';
import JoinGroupModalFooter from './JoinGroupModalFooter';

const headerTitle = 'Create your Community Homepage';

const JoinGroupModal = () => {
  const dispatch = useDispatch();
  const groupList = useSelectGroupList();
  const isJoinGroupModalOpen = useSelector(
    (props: RootState) => props.modal.isJoinGroupModalOpen,
  );
  const isUserSigningUp = useSelector(
    (props: RootState) => props.modal.joinGroupModalMeta.isSigningUp,
  );

  const groups = useMemo(() => filterGroups(groupList), [groupList]);

  const [selectedGroupIds, setSelectedGroupIds] = useState<number[]>([]);

  useEffect(() => {
    setSelectedGroupIds(groups.topThreeGroups.map(group => group.id));
  }, [groups.topThreeGroups]);

  const handleSelectedGroupIds = ids => {
    setSelectedGroupIds([...ids]);
  };

  return (
    <Dialog
      isOpen={isJoinGroupModalOpen}
      headerTitle={headerTitle}
      onCloseClick={() => {
        if (isUserSigningUp) {
          mixpanel.track(trackedEvents.LoginSignUpModal, {
            param: 'close_group',
          });
        }
        dispatch(closeJoinGroupModal({ isSigningUp: false }));
      }}
      data-testId="join-group-modal"
    >
      <div className="h-full lg:h-[70vh] overflow-y-scroll py-4 px-0">
        <JoinGroupContent
          groups={groups}
          selectedGroupIds={selectedGroupIds}
          handleSelectedGroupIds={handleSelectedGroupIds}
        />
      </div>
      <div className="bg-white sticky lg:relative bottom-0 z-10">
        <JoinGroupModalFooter selectedGroupIds={selectedGroupIds} />
      </div>
    </Dialog>
  );
};

export default JoinGroupModal;
