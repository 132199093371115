import React from 'react';
import Button from 'seedly-component-library/button-new';
import Avatar from 'seedly-component-library/avatar-new';
import formatCount from 'app/utils/formatCount';

interface OwnProps {
  name: string;
  discussionsCount: number;
  isSelected: boolean;
  iconImage: string;
  onJoinClick: () => void;
}

const JoinGroupListItem = (props: OwnProps) => {
  const { name, discussionsCount, isSelected, iconImage, onJoinClick } = props;

  return (
    <div
      className="flex w-full items-center justify-between"
      data-testid="join-group-list-item"
    >
      <div className="flex items-center gap-2">
        <Avatar src={iconImage} sizeClass="avatar-10" />
        <div className="flex flex-col gap-1">
          <p>{name}</p>
          <p className="text-xs text-neutral-500">
            {formatCount(discussionsCount)} discussions
          </p>
        </div>
      </div>

      <Button
        onClick={onJoinClick}
        variant={isSelected ? 'primary' : 'outline'}
      >
        {isSelected ? 'Joined' : 'Join'}
      </Button>
    </div>
  );
};

export default JoinGroupListItem;
